import React, { useState } from "react";
import Minus from "images/Minus.svg";
import Plus from "images/Plus.svg";
import NcImage from "shared/NcImage/NcImage";

interface SectionProps {
  title: string;
  children: React.ReactNode;
  isOpen: boolean;
  onToggle: () => void;
}

const Section: React.FC<SectionProps> = ({
  title,
  children,
  isOpen,
  onToggle,
}) => {
  return (
    <div className="border-b border-gray-200">
      <button
        onClick={onToggle}
        className="flex justify-between w-full px-4 py-6 text-left items-center"
      >
        <span className="text-lg font-semibold leading-7 text-left text-white">
          {title}
        </span>
        <span>
          {isOpen ? (
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 11H15M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
                stroke="#E50914"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          ) : (
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 7V15M7 11H15M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
                stroke="#E50914"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          )}
        </span>
      </button>
      {isOpen && <div className="px-4 py-2">{children}</div>}
    </div>
  );
};

const RentalPolicies: React.FC = () => {
  const [openSectionIndex, setOpenSectionIndex] = useState<number | null>(null);

  const handleToggle = (index: number) => {
    setOpenSectionIndex(openSectionIndex === index ? null : index);
  };
  return (
    <>
      <div className="flex justify-center mb-16">
        <span className="text-3xl  font-semibold leading-10 text-center tracking-tight">
          Frequently Asked Questions FAQ
        </span>
      </div>
      <div className="max-w-3xl mx-auto mt-4">
        <Section
          title="What are the requirements to rent an exotic car with Black Rock Rental?"
          isOpen={openSectionIndex === 1}
          onToggle={() => handleToggle(1)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#9CA3AF]">
            To rent an exotic car with Black Rock Rental, customers must be at
            least 21 years of age, possess a valid driver's license, and have
            current auto insurance. Additionally, a valid credit card may be
            required to cover the security deposit.
          </span>
        </Section>
        <Section
          title="What types of exotic cars are available for rent and what are their features?"
          isOpen={openSectionIndex === 2}
          onToggle={() => handleToggle(2)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#9CA3AF]">
            To rent an exotic car with Black Rock Rental, customers must be at
            least 21 years of age, possess a valid driver's license, and have
            current auto insurance. Additionally, a valid credit card may be
            required to cover the security deposit.
          </span>
        </Section>
        <Section
          title="How does the booking process work and what are the rental prices?"
          isOpen={openSectionIndex === 3}
          onToggle={() => handleToggle(3)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#9CA3AF]">
            To rent an exotic car with Black Rock Rental, customers must be at
            least 21 years of age, possess a valid driver's license, and have
            current auto insurance. Additionally, a valid credit card may be
            required to cover the security deposit.
          </span>
        </Section>
        <Section
          title="What happens in case of damage to the vehicle during the rental period?"
          isOpen={openSectionIndex === 4}
          onToggle={() => handleToggle(4)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#9CA3AF]">
            To rent an exotic car with Black Rock Rental, customers must be at
            least 21 years of age, possess a valid driver's license, and have
            current auto insurance. Additionally, a valid credit card may be
            required to cover the security deposit.
          </span>
        </Section>
      </div>
    </>
  );
};

export default RentalPolicies;
