import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import "rc-slider/assets/index.css";
import "./styles/index.scss";
import "./index.css";
import "./fonts/line-awesome-1.3.0/css/line-awesome.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Toaster } from "react-hot-toast";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { DataProvider } from "data/data-provider";
import { globalJson } from "global/global_json";
import serverURL from "api/auth/auth";



// Function to fetch the Stripe key from the backend
async function fetchStripeKey(companyId: number): Promise<string | null> {
  console.log(serverURL)
  try {
    const response = await fetch(
      `${serverURL}/api/payment/publishable-key/?company_id=${companyId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const contentType = response.headers.get("content-type");
    if (!response.ok) {
      const errorText = await response.text();
      console.error("Failed to fetch Stripe key. Status:", response.status);
      console.error("Response Error Body:", errorText);
      return null;
    }

    // Log the full response if it contains JSON
    if (contentType && contentType.includes("application/json")) {
      const data = await response.json();
      console.log("Full API Response JSON:", data); // Log the entire JSON response

      // Check if the publishable_key exists in the data
      if (data.publishable_key) {
        console.log("Fetched Stripe key:", data.publishable_key);
        return data.publishable_key;
      } else {
        console.error("publishable_key is undefined in the API response.");
        return null;
      }
    } else {
      const errorText = await response.text();
      console.error("Unexpected content type:", contentType);
      console.error("Response Error Body:", errorText);
      return null;
    }
  } catch (error) {
    console.error("Error fetching Stripe key:", error);
    return null;
  }
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const RootComponent = () => {
  const [stripePromise, setStripePromise] =
    useState<Promise<Stripe | null> | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initializeStripe = async () => {
      const companyId =
        process.env.REACT_APP_ENV !== "prod"
          ? globalJson.dev_company_id
          : globalJson.prod_company_id;
      console.log("Company ID from globalJson:", companyId); // Log the company ID from globalJson

      if (companyId) {
        const stripeKey = await fetchStripeKey(companyId);
        if (stripeKey) {
          setStripePromise(loadStripe(stripeKey));
        } else {
          console.error("Stripe key is null.");
        }
      } else {
        console.error("Company ID is not available.");
      }

      setLoading(false); // Stop loading once the key is fetched or failed
    };

    initializeStripe();
  }, []);

  // Display a loader while fetching the Stripe key
  if (loading) {
    return (
      <div className="flex justify-center items-center col-span-full min-h-[100vh]">
        <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full border-t-transparent border-gray-700"></div>
      </div>
    );
  }

  // Conditionally render Elements once the stripePromise is available
  return (
    <GoogleOAuthProvider clientId="792199944204-3u7t512ta8hbrhptdjomhqp2nb7jh41h.apps.googleusercontent.com">
      {stripePromise ? (
        <Elements stripe={stripePromise}>
          <DataProvider>
            <App />
            <Toaster containerStyle={{ zIndex: 9999 }} />
          </DataProvider>
        </Elements>
      ) : (
        <div>
          Error loading Stripe. Please check console logs for more details.
        </div>
      )}
    </GoogleOAuthProvider>
  );
};

root.render(
  // <React.StrictMode>
  <RootComponent />
  // </React.StrictMode>
);

reportWebVitals();
